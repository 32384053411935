import classes from "./Carousel.module.css";
import React from "react";
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { images } from "../../assets/img";

const MainCarousel = (props) => {
  const { children, isSmallIcon } = props || {};

  const renderArrow = (props) => {
    const { clickHandler, hasPrev, hasNext } = props;
    return (
      <div
        className={`${classes.arrow} ${isSmallIcon ? classes.smallArrow : ""} ${
          hasPrev ? classes.leftArrow : classes.rightArrow
        }`}
        onClick={clickHandler}
      >
        <img
          className={`${classes.arrowIcon} ${
            isSmallIcon ? classes.smallArrowIcon : ""
          } ${hasPrev ? classes.leftIcon : classes.rightIcon}`}
          alt={"arrow icon"}
          src={images.leftArrow}
        />
      </div>
    );
  };

  const renderIndicator = (clickHandler, isSelected, index, label) => {
    return (
      <div
        onClick={clickHandler}
        className={`${classes.indicator} ${
          isSelected ? classes.selectedIndicator : ""
        }`}
      />
    );
  };

  return (
    <Carousel
      className={`${classes.carouselContainer}`}
      showThumbs={false}
      autoPlay
      emulateTouch
      infiniteLoop
      showStatus={false}
      interval={10000}
      width={"100%"}
      renderArrowPrev={(clickHandler, hasPrev) => {
        return renderArrow({ clickHandler, hasPrev });
      }}
      renderArrowNext={(clickHandler, hasNext) => {
        return renderArrow({ clickHandler, hasNext });
      }}
      renderIndicator={renderIndicator}
      {...props}
    >
      {children}
    </Carousel>
  );
};

export default MainCarousel;
