import { images } from "../assets/img";

export const NAIL_SHOP_INFO = {
  ggMapUrl: "https://maps.app.goo.gl/GFQuWL1sMCmQF9ea8",
  address: "35 Elm Row, Edinburgh EH7 4AH, United Kingdom",
  email: "shinemynailss35@gmail.com",
  tel: "+447387929888",
  phone: "+44 7387 929 888",
  facebookId:"61557195487056",
  instagram: "https://www.instagram.com/shine_my_nails_edinburgh/",
  instagramName: "shine_my_nails_edinburgh",
  name: "Shine My Nails",
  bookingUrl: "https://shinemynails.setmore.com",
  openingTime:{
    openTime:{weekdays: "MONDAY - SATURDAY", time:"09:30 - 18:30"},
    closeTime:{weekdays: "SUNDAY", time:"10:30 - 17:00"}
  }
};

NAIL_SHOP_INFO.instagramChatUrl = `https://ig.me/m/${NAIL_SHOP_INFO.instagramName}`;
NAIL_SHOP_INFO.facebook = `https://www.facebook.com/profile.php?id=${NAIL_SHOP_INFO.facebookId}`
NAIL_SHOP_INFO.facebookMessengerUrl = `http://m.me/${NAIL_SHOP_INFO.facebookId}`

export const SOLCO_INFO = {
  facebook: "https://www.facebook.com/solcoagency.marketing",
};

export const LANGUAGE_ID = {
  vi: "vi",
  en: "en",
};

export const CUSTOMER_FEEDBACK = [
  {
    name: "Heather Braswell",
    feedback:
      "“Beautiful salon, tons of colors and the nicest people…..and most importantly excellent work!   The shop is new but the people are very experienced and it shows.”",
    avatar: "https://i.imgur.com/g7mJPq8.png",
  },
  {
    name: "Jana Rudoll",
    feedback:
      "“Went to Shine My Nails salon today. Very friendly.  My nails were done to an excellent standard. I will definitely go back .  Great prices too. So worth it. One happy customer.”",
    avatar: "https://i.imgur.com/VxekuRS.png",
  },
  {
    name: "Louise Medve",
    feedback:
      "“Thank you Hanna!!! Amazing, friendly and professional service….. absolutely love my acrylic French tips on hands and toes. Beautiful clean and fresh salon with a toilet which is brilliant!! Daughter had gel french tips and loves them. The best Nail Salon in Edinburgh by far ….highly recommend a visit - the massage pedicure chairs are just wonderful ❤️❤️”",
    avatar: "https://i.imgur.com/0JaNnhK.png",
  },
  {
    name: "Lucia Poulter",
    feedback:
      "“Great  salon, I’ve had a pedicure and manicure with different people and am very happy with both. Lovely people and well priced. I’ll definitely be back 👌”",
    avatar: "https://i.imgur.com/aCEUi1W.png",
  },
  {
    name: "Sarah Hickson",
    feedback:
      "“Walked in and was seen to immediately, the staff were so lovely! Had nice conversation while I got BIAB done, and can 100% say this is the best I’ve ever had my nails done in edinburgh. A lovely space, lovely people, and great results. This will definitely be my new nail spot! :)”",
    avatar: "https://i.imgur.com/F7M14q2.png",
  },
  {
    name: "Isabella White",
    feedback:
      "“I booked a last-minute appointment at Shine My Nails, and they exceeded my expectations. My gel manicure has been flawless for weeks!”",
    avatar: "https://i.imgur.com/KxhUFKM.jpg",
  },
  {
    name: "Scarlett Brown",
    feedback:
      "“The calm environment at Shine My Nails makes it my go-to spot. Their tips for maintaining healthy nails have been really helpful.”",
    avatar: "https://i.imgur.com/kI3rsKc.jpg",
  },
  {
    name: "Ella Wilson",
    feedback:
      "“I treated my mom to a day at Shine My Nails for her birthday, and it was perfect. The staff made us feel so special with their attention to detail.”",
    avatar: "https://i.imgur.com/92gXkP8.jpg",
  },
];

export const MENU_HEADER = [
  { name: "Home", navigationKey: "home" },
  { name: "About Us", navigationKey: "about-us" },
  { name: "Services", navigationKey: "services" },
  { name: "Booking", navigationKey: NAIL_SHOP_INFO.bookingUrl },
  // { name: "Contacts", navigationKey: "contactsRef" },
  { name: "Contact", navigationKey: "contact-us" },
  { name: "Policy", navigationKey: "policy" },
];
