// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {fetchAndActivate, getRemoteConfig, getString} from "firebase/remote-config";
import React from "react";
import Helpers from "../utils/Helpers";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBTNs5Rug74wKYbg6HGCPuB7JjCYKmZRdU",
    authDomain: "luxury-nails-spa.firebaseapp.com",
    projectId: "luxury-nails-spa",
    storageBucket: "luxury-nails-spa.appspot.com",
    messagingSenderId: "616004566151",
    appId: "1:616004566151:web:bc3ab1ef163566bd704c22",
    measurementId: "G-VY0W0LPRYS"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = Helpers.isDevelopment ? 60000 : 600000;

const fetchAndActivateRemoteConfig = () => {
    return new Promise((resolve, reject) => {
        fetchAndActivate(remoteConfig)
            .then((a) => {
                resolve(a)
                console.log('Firebase fetch and activate successfully!!')
            })
            .catch((err) => {
                reject(err)
                console.log('Firebase fetch and activate FAILED!!')
            });
    })
}

const getConfigValue = (key) => {
    return Helpers.parseJson(getString(remoteConfig, key))
}

export default {remoteConfig, fetchAndActivateRemoteConfig, getConfigValue};
