import React, {useEffect, useMemo, useState} from "react";

const AuthContext = React.createContext({
    // isLoggedIn: false,
    // onLogout: () => {
    // },
    // onLogin: (email, password) => {
    // },
    isFetchActivateRemoteConfig: false,
    setFetchActivateRemoteConfig: (isFetch) => {
    }
})

export const AuthContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isFetchActivateRemoteConfig, setIsFetchActivateRemoteConfig] = useState(false)

    useEffect(() => {
        const storeUserLogIn = localStorage.getItem('isLogin')
        if (storeUserLogIn === '1') {
            setIsLoggedIn(true)
        }
    }, [])


    const loginHandler = () => {
        localStorage.setItem('isLogin', '1')
        setIsLoggedIn(true)
    }

    const logoutHandler = (email, password) => {
        localStorage.removeItem('isLogin')
        setIsLoggedIn(false)
    }

    const setFetchActivateRemoteConfig = (isFetched) => {
        setIsFetchActivateRemoteConfig(isFetched)
    }

    const values = useMemo(() => {
        return {
            // isLoggedIn,
            // onLogout: logoutHandler,
            // onLogin: loginHandler,
            isFetchActivateRemoteConfig,
            setFetchActivateRemoteConfig
        }
    }, [isLoggedIn, isFetchActivateRemoteConfig])

    return (
        <AuthContext.Provider value={values}>
            {props.children}
        </AuthContext.Provider>

    )
}

export default AuthContext
