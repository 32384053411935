import i18n from "i18next";
import {initReactI18next,useTranslation} from "react-i18next";
import Backend from "i18next-http-backend";
import en from './en.json'
import vi from './vi.json'
import Helpers from '../utils/Helpers'
import {LANGUAGE_ID} from "../utils/Const";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: LANGUAGE_ID.en, // Set the default language
        fallbackLng: LANGUAGE_ID.en, // Fallback language if translation is missing
        debug: Helpers.isDevelopment, // Enable debug mode for development
        interpolation: {
            escapeValue: false // React already escapes the values
        },
        resources: {
            en: {
                translation: en
            },
            vi: {
                translation: vi
            }
        }
    }).then((r) => {
});


export const t = (translationObj) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const { i18n } = useTranslation('home');

    if (typeof translationObj === 'object') {
        const currentLanguage = i18n.resolvedLanguage
        return translationObj?.[currentLanguage]
    } else {
        return i18n.t(translationObj);
    }
}

export default i18n;
