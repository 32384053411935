  export const menuHeaderHandler = ( navigationKey,navigate ) => {
  
    if (navigationKey.startsWith("http")) {
      window.open(navigationKey);
    } else if (navigationKey.endsWith("Ref")) {
      navigate("/", {
        state: {
          focusServices: navigationKey.replace("Ref", ""),
        },
      });
    } else {
      navigate(`/${navigationKey}`);
    }
  
  };