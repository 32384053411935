import React, {useState} from "react";
import classes from "./Booking.module.css";
import {Link} from "react-router-dom";
import PopUpModal from "../PopUpModal/PopUpModal";
import {NAIL_SHOP_INFO} from "../../utils/Const";
import BookNowBtn from "../BookNowBtn";
import {images} from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const Booking = (props) => {
    const {isFromHome} = props || {};
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [guestNumber, setGuestNumber] = useState(1);
    const [description, setDesciprtion] = useState("");
    const [isShowModal, setIsShowModal] = useState(false);
    const isMobile = useCheckIsMobile();

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsShowModal(true);

        // Perform booking submission logic here
        // You can send the form data to an API or perform any other actions

        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        const formattedDate = new Date(date).toLocaleDateString("en-UK", options);
        fetch(
            "https://api.telegram.org/bot7017889083:AAFTt-a34_zI8GVSYyGgKlhYdjHlNlzIC64/sendMessage?chat_id=-1002079400718",
            // "https://api.telegram.org/bot7017889083:AAFTt-a34_zI8GVSYyGgKlhYdjHlNlzIC64/getUpdates",
            {
                method: "post",
                body: JSON.stringify({
                    text: `Name: <strong>${name}</strong> \nPhone number: <strong>${phoneNumber}</strong> \nDate time: <strong>${time} | ${formattedDate}</strong> \nNumber of guests: ${guestNumber} \nServies booked / Description: ${description} \nEmail: <a href='${email}'>${email}</a>`,
                    parse_mode: "html",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((res) => {
                console.log('lahsdfdaf',res)
                if (res?.result?.message_id) {
                    // Reset form fields after submission
                    setIsShowModal(true);
                }
            })
            .catch((_err) => {
            });
    };

    const resetField = () => {
        setName("");
        setEmail("");
        setDate("");
        setTime("");
        setGuestNumber(1);
        setDesciprtion("");
        setPhoneNumber("");
    };

    const bookingForm = () => {
        return (
            <form className={classes.bookingForm} onSubmit={handleSubmit}>
                <Link className={classes.link} to={"/"} rel="noopener noreferrer">
                    <img src={images.leftArrow} alt={'left arrow'}/>
                    <h3>{"Home Page"}</h3>
                </Link>

                <h2>Book Appointment Now</h2>

                <div className={classes.bookingWrapper}>
                    <div className={classes.nailInfo}>
                        <h2>{NAIL_SHOP_INFO.name}</h2>
                        <a target={"_blank"} href={NAIL_SHOP_INFO.ggMapUrl} rel="noreferrer">
                            {`1632 Great Western Rd, Anniesland, ${isMobile ? " " : "\n"}Glasgow G13 1HH, United Kingdom\n\n`}
                        </a>
                        <a
                            href={`tel:${NAIL_SHOP_INFO.tel}`}
                        >{`Hotline: ${NAIL_SHOP_INFO.phone}\n\n`}</a>
                        <a
                            href={`mailto:${NAIL_SHOP_INFO.email}`}
                        >{`Email: ${NAIL_SHOP_INFO.email}`}</a>
                    </div>

                    <div className={classes.bookingInfoInput}>
                        <label>
                            Name:
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </label>

                        <label>
                            Phone Number:
                            <input
                                type="tel"
                                id="phoneNumber"
                                value={phoneNumber}
                                required
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </label>

                        <label>
                            Date:
                            <input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </label>

                        <label>
                            Time:
                            <input
                                type="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                required
                            />
                        </label>

                        <label>
                            Number of guests:
                            <input
                                type="number"
                                value={guestNumber}
                                onChange={(e) => setGuestNumber(e.target.value)}
                                required
                            />
                        </label>

                        <label>
                            {`Services booked /\nDescription:`}
                            <textarea
                                value={description}
                                onChange={(e) => setDesciprtion(e.target.value)}
                                placeholder={"Manicures, Pedicures, Nail art, Nail extension,..."}
                                rows="5"
                            ></textarea>
                        </label>

                        <label>
                            Email:
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>

                        <BookNowBtn isShowArrow={false} type="submit" style={classes.bookBtn}/>
                    </div>
                </div>
            </form>
        );
    };

    return (
        <div ref={props?.bookingRef} className={classes.booking}>
            {bookingForm()}
            <PopUpModal
                isShow={isShowModal}
                title={"Booking Confirmed"}
                message={
                    `Thanks for booking with us! We’re received your appointment. Hope to see you soon!\nIf you want to make changes to your booking,\nplease contact our\n\nHotline: ${NAIL_SHOP_INFO.phone}\nEmail: ${NAIL_SHOP_INFO.email}`
                }
                infoData={{
                    name,
                    email,
                    date,
                    time,
                    phoneNumber,
                    guestNumber,
                    description,
                }}
                primaryText={"OK"}
                secondaryText={ "Home Page"}
                onPrimaryClick={() => {
                    setIsShowModal(false);
                    resetField();
                }}
                onSecondaryClick={() => {
                    setIsShowModal(false);
                    window.location.href = "/";
                }}
            />
        </div>
    );
};

export default Booking
