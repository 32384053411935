import React, { useEffect, useState } from "react";
import classes from "./Menu.module.css";
import { useNavigate } from "react-router-dom";
import { MENU_HEADER, NAIL_SHOP_INFO, SOLCO_INFO } from "../../utils/Const";
import { CloseIcon, MenuIcon } from "../../assets/icon";
import { menuHeaderHandler } from "../../navigation/MenuHeaderHandler";

const Menu = ({
  isClose,
  introductionRef,
  serviceRef,
  contactsRef,
  selected,
}) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setIsDropdownOpen(isClose);
  }, [isClose]);

  useEffect(() => {
    window.addEventListener("scroll", detectScrollEnd);

    return () => {
      window.addEventListener("scroll", detectScrollEnd);
    };
  }, []);

  function detectScrollEnd() {
    setIsDropdownOpen(false);
  }

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const onClickMenuHeader = (navigationKey) => () => {
    menuHeaderHandler(navigationKey, navigate);
    toggleDropdown();
  };

  return (
    <div
      className={`${classes.mobileHeader} ${
        isDropdownOpen ? classes.open : ""
      }`}
    >
      <button className={classes.menuButton} type="button"  onClick={toggleDropdown}>
        <MenuIcon />
      </button>
      <div
        className={`${classes.dropdownContent} ${
          isDropdownOpen ? "" : classes.fadeOut
        }`}
      >
        <div className={classes.menuHeader}>
          <h2>{NAIL_SHOP_INFO.name}</h2>
          <button className={classes.closeButton} type="button" onClick={toggleDropdown}>
            <CloseIcon />
          </button>
        </div>

        <ul>
          {MENU_HEADER.map((item, index) => {
            const { name, navigationKey } = item || {};
            return (
              <li
                className={
                  selected === navigationKey ? classes.selectedItem : ""
                }
                onClick={onClickMenuHeader(navigationKey)}
              >
                {name}
              </li>
            );
          })}
        </ul>

        <div className={classes.creditFooter}>
          <h4>
            {`Copyright © 2024 ${NAIL_SHOP_INFO.name}. All rights reserved. Designed by `}
            <a target={"_blank"} href={SOLCO_INFO.facebook} rel="noreferrer">
              {`SOLCO Marketing Agency`}
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Menu;
