import React, { useContext, useEffect, useState } from "react";
import classes from "./Services.module.css";
import AuthContext from "../../store/auth-context";
import Helpers from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/img";
import RemoteConfig from "../../services/RemoteConfig";
import BookNowBtn from "../BookNowBtn";
import "../../index.css";

const SERVICES = [
  {
    title: "Hands Gel Polish",
    description: "SHELLAC",
    image: "https://i.imgur.com/NNpVLLe.jpeg",
    price: "£20",
  },
  {
    title: "Toes Gel Polish",
    description: "",
    image: "https://i.imgur.com/D2HJoWa.jpeg",
    price: "£22",
  },
  {
    title: "Hands Builder Gel",
    description: "BIAB",
    image: "https://i.imgur.com/pBjO4ry.jpeg",
    price: "£27",
  },
  {
    title: "Hands Full Manicure + Gel",
    description: "",
    image: "https://i.imgur.com/PtkDJFt.jpeg",
    price: "£25",
  },

  {
    title: "Nail Extensions Colour Power",
    description: "Full Set",
    image: "https://i.imgur.com/A9BUYT2.jpeg",
    price: "£33",
  },
];

const ServiceItem = (item, index, onPress) => {
  const { title, image, description, price } = item || {};
  return (
    <div
      onClick={onPress}
      key={`${title + index}`}
      className={`${classes.serviceItem}`}
    >
      <div className={classes.rowContainer}>
        <div className={`${classes.roundDiv}`}>
          <img alt={"nail"} src={image} />
        </div>
        <div>
          <h3 className={classes["change-color-on-hover"]}>{title}</h3>
          <h4 className={classes["change-color-on-hover"]}>{description}</h4>
        </div>
      </div>

      <h3 className={classes["change-color-on-hover"]}>{price}</h3>
    </div>
  );
};

const Services = (props) => {
  const navigate = useNavigate();

  const onPress = () => {
    navigate(`/services`);
  };

  return (
    <div ref={props?.serviceRef} className={classes.wrapper}>
      <h1>Our Services</h1>

      <div className={classes.rowDirection}>
        <div className={classes.container}>
          {Helpers.isArrayAvailable(SERVICES)
            ? SERVICES.map((item, index) => {
                return ServiceItem(item, index, onPress);
              })
            : null}
        </div>
        <img
            alt={"Service"}
            src={images.nail3}
            className={classes.serviceImg}
          />
      </div>

      <BookNowBtn
        style={classes.priceListBtn}
        onClick={() => navigate("/services")}
        title={"VIEW PRICING"}
      />
    </div>
  );
};

export default Services;
