import { useEffect, useState } from "react";
import styled from "styled-components";
import { images } from "../../assets/img";
import classes from "./BackToTop.module.css";
import { NAIL_SHOP_INFO } from "../../utils/Const";

const Text = styled.h3`
  position: fixed;
  bottom: 1.5vh;
  right: 3vw;
  color: #f4592f;
  white-space: pre-wrap;
  cursor: pointer;
  border-left: 1px solid gray;
  padding-left: 3px;
  opacity: ${(props) => (props?.isShow ? "1" : "0")};
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #81b1ef;
    border-left: 1px solid #81b1ef;
  }
`;

const Container = styled.div`
  position: fixed;
  bottom: 1.5vh;
  right: 2vw;
  background-color: #bc1823;
  border-radius: 120px;
  padding: 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 14px;
    bottom: 3vh;
  }
`;

const Image = styled.img.attrs({
  src: images.leftArrow,
})`
  width: 40px;
  height: 36px;
  transform: rotate(90deg);

  @media (max-width: 768px) {
    width: 26px;
    height: 20px;
  }
`;

export default function BackToTop() {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", detectScrollEnd);

    return () => {
      window.addEventListener("scroll", detectScrollEnd);
    };
  }, []);

  function detectScrollEnd() {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= scrollHeight * 0.4) {
      setIsShow(true);
      return;
    }

    setIsShow(false);
  }

  if (!isShow) {
    return null;
  }

  function onClickToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Container onClick={onClickToTop}>
        <Image/>
    </Container>
  );
}
