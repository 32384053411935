import classes from "./Introduction.module.css";
import React, { useEffect, useContext, useState } from "react";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

//ABOUT US
const Introduction = (props) => {
  const { introductionRef } = props || {};
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [image, setImage] = useState(images.nail_shop02);
  const isMobile = useCheckIsMobile();

  // useEffect(() => {
  //   const introduction_image = RemoteConfig.getConfigValue("introduction_image");
  //   introduction_image && setImage(introduction_image);
  // }, [isFetchActivateRemoteConfig]);

  return (
    <div ref={introductionRef} className={classes.introduction}>
      <div className={classes.introHeader}>
        <h2 className={classes.cursiveText}>{NAIL_SHOP_INFO.name}</h2>
      </div>
      <div className={classes.rowWrapper}>
        <img alt={"Intro"} src={image} className={classes.introImg} />
        <div className={classes.introTextContainer}>
          <div>
          <h1>{"WHERE BEAUTY MEET RELAXATION!"}</h1>
            <h3 className={classes.greyIntroTextContainer}>
              Shine My Nails - Premier Nail Salon in Edinburgh
            </h3>
          </div>
          <p className={classes.greyIntroTextContainer}>
            Step into Shine My Nails, your new go-to destination at 35 Elm Row,
            Edinburgh. Here, we combine expert craftsmanship with a welcoming
            atmosphere to deliver nail services that stand out. Our passionate
            team is dedicated to bringing your nail vision to life, whether
            you're drawn to timeless styles like a classic manicure or seeking
            unique, customized nail art. At Shine My Nails, your hands and feet
            are in the best care.
          </p>
          <p className={classes.greyIntroTextContainer}>
            Trust us with your next nail experience, and discover why Shine My
            Nails is quickly becoming a top choice in Edinburgh. Our
            comprehensive range of services is designed to leave your nails
            looking flawless and feeling healthy every time you visit.
          </p>
          <BookNowBtn />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
