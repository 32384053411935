import React from "react";
import classes from "./CustomerFeedback.module.css";
import MainCarousel from "../Carousel/Carousel";
import { CUSTOMER_FEEDBACK, NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const CustomerFeedback = (props) => {
  const isMobile = useCheckIsMobile()

  const renderCustomerFeedback = () => {
    function chunkArray(arr, chunkSize) {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
      }
      return result;
    }

    const chunkedFeedbackArray = chunkArray(CUSTOMER_FEEDBACK,isMobile ? 1 : 3);

    return chunkedFeedbackArray.map((feedbackGroup) => {
      return (
        <div className={classes.feedbackRow}>
          {feedbackGroup.map((feedbackItem) => {
            const { name, feedback, avatar } = feedbackItem || {};
            return (
              <div className={classes.feedbackItem}>
                <div className={classes.feedbackAvatarRow}>
                  <img
                    className={classes.feedbackImg}
                    alt={`feedback avatar of ${name}`}
                    src={avatar}
                  />
                  <div className={classes.customerNameColumn}>
                    <h3>{name}</h3>
                    <h4>{"⭐⭐⭐⭐⭐"}</h4>
                  </div>
                </div>
                <h4>{feedback}</h4>
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.cursiveText}>{`${NAIL_SHOP_INFO.name}'s feedback`}</h1>
      <MainCarousel>{renderCustomerFeedback()}</MainCarousel>
    </div>
  );
};

export default CustomerFeedback;
