import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./PopUpModal.module.css";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onBackDropPress} />;
};

const ModalOverlay = (props) => {
  const {
    title,
    message,
    infoData,
    onPrimaryClick,
    onSecondaryClick,
    primaryText,
    secondaryText,
  } = props;

  const { name, email, date, time, phoneNumber, guestNumber, description } =
    infoData;

  return (
    <div className={classes.modal}>
      <header className={classes.header}>
        <h2>{title}</h2>
      </header>
      <div className={classes.content}>
        <h3>{message}</h3>
      </div>

      <h3 className={classes.info}>{`Name: ${name}`}</h3>
      <h3 className={classes.info}>{`Email: ${email}`}</h3>
      <h3 className={classes.info}>{`Date: ${date}`}</h3>
      <h3 className={classes.info}>{`Time: ${time}`}</h3>
      <h3 className={classes.info}>{`Phone: ${phoneNumber}`}</h3>
      <h3 className={classes.info}>{`Number of guest(s): ${guestNumber}`}</h3>
      <h3
        className={classes.info}
      >{`Service booked / Description: ${description}`}</h3>

      <footer className={classes.actions}>
        <h3 onClick={onPrimaryClick}>{primaryText}</h3>
        {!!secondaryText && <h3 onClick={onSecondaryClick}>{secondaryText}</h3>}
      </footer>
    </div>
  );
};

const PopUpModal = (props) => {
  const {
    isShow,
    title,
    message,
    infoData,
    onPrimaryClick,
    onSecondaryClick,
    primaryText,
    secondaryText,
    closeOnBackDrop = false,
  } = props;

  const [isShowModal, setIsShowModal] = useState(!!isShow);

  useEffect(() => {
    setIsShowModal(isShow);
  }, [isShow]);

  const onClosePopUp = () => {
    setIsShowModal(false);
  };
  const onBackDropPress = () => {
    if (closeOnBackDrop) {
      onClosePopUp();
    }
  };

  if (!isShowModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onBackDropPress={onBackDropPress} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={title}
          message={message}
          infoData={infoData}
          onPrimaryClick={onPrimaryClick}
          onSecondaryClick={onSecondaryClick}
          primaryText={primaryText}
          secondaryText={secondaryText}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default PopUpModal;
