import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./Policy.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";
import BookNowBtn from "../../components/BookNowBtn";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const Policy = () => {
  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Policy"}</title>
        <meta name="policy" content="Policy" />
      </Helmet>
      <Header selected={"policy"} />

      <div className={classes.policyContainer}>
        <h2 className={classes.textAlignCenter}>
          {
            "SALON POLICY\nOUR POLICY DOES NOT AFFECT YOUR RIGHTS UNDER THE CONSUMER RIGHTS ACT 2015"
          }
        </h2>

        <h3 className={classes.titleCenterUnderline}>OUR CUSTOMER</h3>

        <ol>
          <li>Ask for the service which you would like to have.</li>
          <li>
            Inform our staff if you are allergic to any chemicals or substances
            before receiving any service.
          </li>
          <li>
            Whilst receiving any service, please provide our staff with your
            feedback and/or comments on their services so our staff can satisfy
            your requirements straight away.
          </li>
          <li>
            Children under 12 years old should be accompanied by an adult at all
            times. We will not take any responsibility if any accident happens
            to your child/children during your treatment at our salon.
          </li>
          <li>
            We hold no responsibility for the loss of your personal belongings
            while you are having the treatment at our salon.
          </li>
          <li>No food or drink should be consumed during your treatment.</li>
          <li>No pets will be allowed in the salon, except guide dogs.</li>
          <li>
            We reserve the right to refuse service to any customer:
            <ul>
              <li type="a">
                Who does not follow our salon policy/risk assessment related to
                the pandemic.
              </li>
              <li type="a">
                Who uses abusive language or threatening behavior.
              </li>
              <li type="a">
                Who will be a risk to themselves or cause risk to our staff
                should they use a service (in respect of the Equal Opportunities
                Act and Disability Act).
              </li>
            </ul>
          </li>
          <li>
            For appointment only:
            <ul>
              <li type="a">Customers should arrive on time.</li>
              <li type="a">
                If the customer is 10 minutes late or more, the appointment may
                have to be rescheduled.
              </li>
              <li type="a">
                Salon may be late due to service overrun; please bear this in
                mind.
              </li>
              <li type="a">
                Any cancellations should be informed 24-48 hours before the
                appointment; otherwise, there will be a charge for late
                cancellation.
              </li>
            </ul>
          </li>
        </ol>

        <br/>

        <h3 className={classes.titleCenterUnderline}>{"SERVICE GUARANTEE"}</h3>
        <h3>{"Customer must follow the Aftercare for the service"}</h3>

        <ol>
          <li>Traditional polish services are not guaranteed.</li>
          <li>
            Enhancements and gel polish manicures are guaranteed for 72 hours
            after your appointment. If you lose an enhancement or notice chips
            or lifting within 72 hours, please call us to schedule a free
            repair.
          </li>
        </ol>

        <br/>

        <h3 className={classes.titleCenterUnderline}>{"COMPLAINTS"}</h3>
        <h3>{"Customer must inform us by phone, text or email"}</h3>

        <ol>
          <li>
            You must arrange an appointment to have your nails fixed within 5
            days after your initial service.
          </li>
          <li>
            Repairs after 3 days or for breakages, tears, and corner breaks will
            be charged.
          </li>
          <li>
            We will not be responsible for any breaking or infected nails 72
            hours after you leave the salon due to your negligence and/or not
            following the nail after care.
          </li>
          <li>
            Any infection/breakage/allergy should be reported to the salon
            formally within 48 hours. After this time frame the salon will not
            be liable.
          </li>
        </ol>

        <br/>

        <h3 className={classes.titleCenterUnderline}>
          {
            "REFUND"
          }
        </h3>
        <h3>
          {
            "No cash refunds will be given after you have left the salon. As soon as you have walked out the salon door that means you have accepted and are happy with the service provided to your nails."
          }
        </h3>
        <ol>
          <li>
            If you are not happy with your nails, please make us aware of it
            before you pay. We will adjust them to your satisfaction or remove
            any enhancements or coatings that have been applied.
          </li>
          <li>
            No refund will be given because you have simply changed your mind.
          </li>
          <li>
            We will correct any service at no cost to you provided that you tell
            us immediately after we have perform the service that you're unhappy
            with the quality of the work. If you're still not happy after we
            fixed your nails and request the refund, then you have to go through
            our Customer Complaint Procedure in writing to us state the reason
            you are unsatisfied after we redid your nails and provide a photo of
            evidence of the nails that were fixed by us.
          </li>
        </ol>
      </div>

      <h1>Gift Voucher Available</h1>
      <BookNowBtn style={classes.bookNowBtnStyle} />

      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />
    </div>
  );
};

export default Policy;
